import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, IconButton, PencilIcon, Table } from '@flotilla/component-library';

import { getAllActions } from '../../../../api/netZeroPlan';
import { Action, Actions, impactAreaValues, potentialType } from '../../../../types/Action';
import { AddActionModal } from '../../../../components/Modal';
import { useAppSelector } from '../../../../helpers/hooks';
import { getNetZeroPlanId } from '../../../../reducers/user';
import { useCompanyId, useUserAccess } from '../../../../context';
import { ACTION_LIBRARY_SUBTITLE } from '../../../../assets/content/Subtitles';
import ActionRow from './ActionRow';
import Header from '../../../../components/HeaderV2';
import useCachedFilters from '../../../../hooks/PageCaching/useCachedFilters';

interface ActionLibraryProps {
  className?: string;
};

type CombinedFilters = {
  quickFilters: Filters;
  filters: Filters;
}

const DEFAULT_FILTERS: CombinedFilters = {
  quickFilters: [
    {
      key: 'impactAreaName',
      label: 'Impact Area',
      sort: (a: string, b: string) => impactAreaValues.indexOf(a) - impactAreaValues.indexOf(b),
    },
    {
      key: 'scopeName',
      label: 'Scope',
      options: ['Scope 1', 'Scope 2', 'Scope 3'],
      addLabel: true,
    },
    {
      key: 'recommended',
      label: 'Recommended',
      type: 'boolean',
      addLabel: true,
    }
  ],
  filters: [
    {
      key: "notInPlan",
      label: "Hide actions in plan",
      value: "Not in Plan",
      type: "boolean"
    },
    {
      key: "companyId",
      label: "Custom Actions",
      value: "Custom Actions",
      type: "boolean"
    },
    {
      key: "quickWin",
      label: "Quick Wins",
      value: "Quick Wins",
      type: "boolean"
    },
    {
      key: "employeeSuggested",
      label: "Employee Suggested",
      value: "Employee Suggested",
      type: "boolean"
    },
    {
      key: "defaultPriority",
      label: "Priority",
      addLabel: true,
    },
    {
      key: "costSavingsPotential",
      label: "Cost Savings",
      sort: (a: string, b: string) => potentialType.indexOf(a) - potentialType.indexOf(b)
    },
    {
      key: "carbonSavingsPotential",
      label: "Carbon Savings",
      sort: (a: string, b: string) => potentialType.indexOf(a) - potentialType.indexOf(b)
    },
    {
      key: "coBenefitsPotential",
      label: "Corporate Co-Benefits",
      sort: (a: string, b: string) => potentialType.indexOf(a) - potentialType.indexOf(b)
    },
    {
      key: "actionTags",
      label: "Action Tag",
      isArray: true
    },
    {
      key: "sdgNames",
      label: "Sustainable Development Goals",
      isArray: true
    },
    {
      key: "scopeCategoryNames",
      label: "Scope Categories",
      isArray: true
    },
  ]
}

const ActionLibrary: React.FC<ActionLibraryProps> = ({
  className = "",
}) => {
  const { userAccess } = useUserAccess();
  const { NET_ZERO_PLAN_EDIT, STANDARD_PRODUCT_ACCESS } = userAccess;
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [addAction, setAddAction] = useState<Action>();
  const [actions, setActions] = useState<Actions>();
  const { filterData = DEFAULT_FILTERS, onFiltersChange } = useCachedFilters("ActionLibraryFilters");

  useEffect(() => {
    setIsPageLoading(true);
    netZeroPlanId && getAllActions(netZeroPlanId, companyId)
      .then((res) => setActions(res))
      .finally(() => setIsPageLoading(false));
  }, [netZeroPlanId, companyId]);

  const handleAddActionButtonClick = (action: Action) => {
    setAddAction(action);
  }

  const handleClickAction = (id: string | number): React.MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      navigate(`./${id}`);
    }
  }

  const updateAction = () => {
    netZeroPlanId && getAllActions(netZeroPlanId, companyId)
      .then((res) => setActions(res));
  }

  return (
    <section id="action-library-page" className={className}>
      <Header subtitle={ACTION_LIBRARY_SUBTITLE}
        rightChildren={
          () => NET_ZERO_PLAN_EDIT && STANDARD_PRODUCT_ACCESS && (
            <IconButton 
              id="create-btn"
              icon={<PencilIcon stroke='#FBFAF8' />}
              label='Create'
              smartLayout
              onClick={() => navigate("new")}
            >Create</IconButton>
          )
        }>
        Action Library
      </Header>
      <main>
        <Table
          key={JSON.stringify(actions)}
          id="action-library"
          instanceName="ActionLibrary"
          isLoading={isPageLoading}
          stickyHeader
          dataTable
          onFiltersChange={onFiltersChange}
          quickFilters={filterData?.quickFilters}
          filters={filterData?.filters}
          headers={[
            {
              key: "title",
              label: "Name",
              fixed: true
            },
            {
              key: "scopeId",
              label: "Scope",
              optional: true
            },
            {
              key: "defaultPriority",
              label: "Priority",
              type: "number"
            },
            {
              key: "departmentsString",
              label: "Departments",
              optional: true
            },
            {
              key: "costSavingsPotential",
              label: "Cost Savings"
            },
            {
              key: "carbonSavingsPotential",
              label: "Carbon Savings"
            },
            {
              key: "coBenefitsPotential",
              label: "Co-Benefits"
            },
            {
              key: "avoidancePercent",
              label: "Estimated Reduction %",
              type: "number",
              initialDescSort: true
            },
            {
              key: "ranking",
              label: "Suitability Ranking",
              fixed: true,
              tooltip: `This is a ranking of how suitable we believe this action is for you for achieving your net zero goals.

Our complex algorithm analyses your data along with the action data to find the best matches for you which enables you to deliver a robust, data driven net zero plan.`
            },
            {
              key: "quickWin",
              label: "Quick Win",
              optional: true
            },
            {
              key: "impactAreaName",
              label: "Impact Area",
              optional: true
            },
            {
              key: "focusGroupName",
              label: "Focus Group",
              optional: true
            },
            {
              key: "scopeCategoryNames",
              label: "Scope Category",
              optional: true
            },
            {
              key: "add",
              label: " ",
              optional: false,
            },
          ]}
          data={actions as unknown as { [key: string]: string }[]}
          rowComponent={(_item, headers) => {
            const item = _item as unknown as Action
            return (
              <ActionRow
                key={`${item.id} - ${item.recommended ? 'Recommended' : 'Unrecommended'}`}
                item={item}
                headers={headers}
                netZeroPlanId={netZeroPlanId}
                handleClickAction={handleClickAction}
                handleAddActionButtonClick={handleAddActionButtonClick}
                onRefresh={updateAction}
              />
            )
          }}
        />
        {
          addAction && netZeroPlanId && (
            <AddActionModal
              action={addAction}
              netZeroPlanId={netZeroPlanId}
              onClose={() => setAddAction(undefined)}
              onSubmit={() => updateAction()}
            />
          )
        }
      </main>
    </section>
  );
}

export default ActionLibrary;
