import './styles.scss';

import React, { HTMLAttributes, MouseEventHandler, useState } from 'react';
import { Avatar, Card, HierarchyIcon, IconButton, Menu, MenuItem, MoreIcon, Tag } from '@flotilla/component-library';

import { Company } from '../../../../../types/Company';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/Phone.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/icons/Email.svg';
import { useAppDispatch } from '../../../../../helpers/hooks';
import { addModal, removeModal } from '../../../../../reducers/modal';
import EditCustomerModal from '../../../../../components/Modal/EditCustomerModal';
import { useUserAccess } from '../../../../../context';
import { CreateCustomerModal, InviteUserModal } from '../../../../../components/Modal';
import { environment } from '../../../../../helpers/environment';
import isParentCompany from '../../../../../helpers/isParentCompany';

interface CompanyCardProps extends HTMLAttributes<HTMLElement> {
  data: Company;
  onChange?: () => void;
};

const CompanyCard: React.FC<CompanyCardProps> = ({
  className = "",
  data,
  onChange = () => ({}),
  ...props
}) => {
  const dispatch = useAppDispatch();
  const env = environment();
  const { userAccess } = useUserAccess();
  const { ADMIN } = userAccess;
  const [showMenu, setShowMenu] = useState(false);
  const {
    identityCompanyId,
    name = "",
    mainContactName: subtitle = "",
    logoUrl,
    mainContactEmail: email,
    mainContactTel: phone,
    companyType,
    parentName,
    product
  } = data;

  const handleOnClickMore: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  const handleOnClickEdit: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(addModal({
      id: 'edit-customer',
      children: (
        <EditCustomerModal
          data={data}
          onClose={(change) => {
            change && onChange();
            dispatch(removeModal());
          }}
        />
      )
    }));
  }

  const handleOnClickAdd: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(addModal({
      id: 'add-customer',
      children: (
        <CreateCustomerModal
          parentCompany={{
            value: name,
            ...data
          }}
          onClose={(change) => {
            change && onChange();
            dispatch(removeModal());
          }}
        />
      )
    }));
  }

  const handleOnClickAddUser: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(addModal({
      id: 'create-user',
      children: (
        <InviteUserModal
          inviteCompanyId={identityCompanyId}
          onClose={() => dispatch(removeModal())}
        />
      )
    }));
  }

  const handleOnClickCopyLink: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    const supplierSurveyLink = new URL(`${env.employee.baseUrl}/supplier-survey`);
    supplierSurveyLink.searchParams.append('identityCompanyId', String(identityCompanyId));
    supplierSurveyLink.searchParams.append('companyName', name);
    navigator.clipboard.writeText(supplierSurveyLink.href);
    setShowMenu(false);
  }

  return (
    <Card className={`company-card ${className}`} {...props}>
      <header>
        <Avatar
          className="customer-avatar"
          imgSrc={logoUrl}
          imgSrcAlt={`${name} logo`}
        />
        <section className="title-subtitle-container">
          <p className="title">{name}</p>
          <p className="subtitle">{subtitle}</p>
        </section>
        { ADMIN &&  (
          <>
            <IconButton
              id="more-button"
              variant="ghost"
              aria-label="More"
              label="More"
              onClick={handleOnClickMore}
              icon={<MoreIcon variant='dark' />}
              title="More"
            />
            { showMenu && (
              <Menu id="more-menu" onClose={() => setShowMenu(false)}>
                  <MenuItem onClick={handleOnClickAddUser}>Invite user</MenuItem>
                  <MenuItem onClick={handleOnClickEdit}>Edit</MenuItem>
                  { isParentCompany(companyType)  ? (
                    <MenuItem onClick={handleOnClickAdd}>Add Company</MenuItem>
                  ) : <></>}
                  { ADMIN && <MenuItem onClick={handleOnClickCopyLink}>Copy Supplier Survey link</MenuItem>}
              </Menu>
            )}
          </>
        )}
      </header>
      <main>
        <section id="tags">
          { companyType && (
            <Tag size="small" title={`Company type: ${companyType.label}`}>{companyType.label}</Tag>
          )}
          { product && (
            <Tag size="small" title={`Product type: ${product.label}`}>{product.label}</Tag>
          )}
          { parentName && (
            <Tag id="parent-company" size="small" title={`Parent company: ${parentName}`} icon={<HierarchyIcon variant='dark' />}>{parentName}</Tag>
          )}
        </section>
        {
          (email || phone) &&
            <section className="contact-info-container">
              {
                email &&
                  <section className="icon-text-container">
                    <EmailIcon />
                    <a href={`mailto:${email}`} onClick={e => e.stopPropagation()} className="email">{email}</a>
                  </section>
              }
              {
                phone &&
                  <section className="icon-text-container">
                    <PhoneIcon />
                    <a href={`tel:${phone}`} onClick={e => e.stopPropagation()} className="phone">{phone}</a>
                  </section>
              }
            </section>
        }
      </main>
    </Card>
  );
}

export default CompanyCard;
