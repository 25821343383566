import './styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { Blocker, useNavigate } from 'react-router-dom';
import {
  IconButton, Tab, Tabs, SaveIcon, Tag, useAlert, TickIcon,
  HistoryIcon
} from '@flotilla/component-library';
import Joyride, { ACTIONS, CallBackProps, STATUS } from 'react-joyride';

import { DATA_COLLECTION_DATA_DETAIL_SUBTITLE } from '../../../../assets/content/Subtitles';
import { ReactComponent as BookIcon } from '../../../../assets/icons/Book.svg';
import Header from '../../../../components/HeaderV2';
import SaveChangesModal from '../../../../components/Modal/SaveChangesModal';
import DataPeriodSubmitModal from '../../../../components/Modal/DataPeriodSubmitModal';
import RequiredMarker from '../../../../components/RequiredMarker';
import ScopeTag from '../../../../components/ScopeTag';
import { DATA_COLLECTION_TABS_STEPS, TOUR_STYLES } from '../../../../constants/SiteTours';
import useDataPeriod from '../../../../hooks/DataPeriod/useDataPeriod';
import FinancialsTabContent from './Tabs/Financials';
import DataPeriodTabContent from './Tabs/DataPeriod';
import LocationsTabContent from './Tabs/Locations';
import { ActivityDataItem, ActivityNote, Compliance, DataPeriod, DataPeriodTabs, FocusGroups } from '../../../../types/DataPeriod';
import { useUserAccess } from '../../../../context';
import AuditingTabContent from './Tabs/Auditing';

interface DataDetailProps {
  className?: string;
};

const DataDetail: React.FC<DataDetailProps> = ({ className = "" }) => {
  const { userAccess } = useUserAccess();
  const { DATA_EDIT } = userAccess;
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [openAccordions, setOpenAccordions] = useState<number[]>();
  const [complianceFilters, setComplianceFilters] = useState<number[]>([]);
  const [scopeFilters, setScopeFilters] = useState<number[]>([]);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [filteredDataPeriodTabs, setFilteredDataPeriodTabs] = useState<DataPeriodTabs>([]);
  const [isTourActive, setIsTourActive] = useState(false);
  const [activeTabId, setActiveTabId] = useState<number>();

  const {
    dataPeriod,
    dataPeriodTabs,
    dataPeriodTabsMandatory,
    emissionData,
    updatedDataPeriod,
    loading,
    saveDataPeriod,
    updateDataPeriod,
    updateEmissionData,
    updateNotes,
    submitDataPeriod
  } = useDataPeriod();

  const title = dataPeriod ? `${dataPeriod.name} (${new Date(dataPeriod?.startDate || '').toLocaleDateString()} - ${new Date(dataPeriod?.endDate || '').toLocaleDateString()})` : "Data Period Detail";

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;

    if(status === STATUS.SKIPPED || action === ACTIONS.CLOSE || status === STATUS.FINISHED) {
      setIsTourActive(false);
    }
  }

  const handleSaveDataPeriod = (autosave: boolean = false) => {
    if(!autosave && !validateEmissionsData()) return;
    DATA_EDIT && saveDataPeriod(autosave);
  }

  const handleOnChangeDataPeriod = (data: Partial<DataPeriod>) => {
    updateDataPeriod(data);
  }

  const handleOnChangeTabs = (data: ActivityDataItem) => {
    updateEmissionData(data);
  }


  const handleOnChangeNotes = (data: ActivityNote) => {
    updateNotes(data);
  }

  const handleOnClickModalSaveLeave = (navigateBlocker: Blocker) => {
    handleSaveDataPeriod();
    navigateBlocker.proceed?.();
  }

  const handleOnBackClick = () => {
    navigate('..');
  };

  const handleOnClickSubmit = () => {
    if(!validateEmissionsData()) return;
    if (DATA_EDIT) {
      setSubmitAttempt(false);
      saveDataPeriod()
        .then((res) => {
          if ((res && (res.length || 0) > 0) || isFinancialsIncomplete()) {
            addAlert({
              id: `Data Period Mandatory Fields`,
              type: "warning",
              title: "Data Period Mandatory Fields Remaining",
              content: "You cannot submit this data period until all mandatory fields are completed. If a field is not applicable to your business, please enter a value of 0. All fields in Financials Tab should be greater than 0."
            });
            setSubmitAttempt(true);
          } else {
            setShowSubmitModal(true);
          }
        });
    }
  }

  const handleOnClickModalSubmit = (signature: string) => {
    submitDataPeriod(signature)
      .then((res) => {
        if (res) {
          setShowSubmitModal(false);
        }
      });
  }

  const handleOnClickComplianceTag = (compliance: Compliance) => {
    return () => {
      setComplianceFilters((prev) =>
        prev.includes(compliance.id) ?
          prev.filter((id) => id !== compliance.id) :
          [...prev, compliance.id]
      );
    }
  }

  const handleOnClickScopeTag = (scope: number) => {
    return () => {
      setScopeFilters((prev) =>
        prev.includes(scope) ?
          prev.filter((value) => value !== scope) :
          [...prev, scope]
      );
    }
  }

  const validateEmissionsData = () => {
    var invalidData = emissionData?.filter(e => (e.maxValue && (e.value ?? 0) > e.maxValue) || (e.value ?? 0) < e.minValue);
    if(invalidData.length) {
      addAlert({ id: `Data Period Save Failed`, type: "error", title: "Data Period Save Unsuccessful", content: "One or more emission data values aren't valid." });
      setComplianceFilters([]);
      setScopeFilters([]);

      var tabIdx = dataPeriodTabs?.findIndex(t => 
        t.focusGroups.find(fg => fg.activities.find(a => a.data.find(d => d === invalidData[0]))) ||
        t.locations?.find(l => l.focusGroups?.find(fg => fg.activities.find(a => a.data.find(d => d === invalidData[0]))))
      );

      if(tabIdx !== undefined && tabIdx > -1) {
        setActiveTabId(tabIdx);
      }

      const accordionsIds = dataPeriodTabs?.flatMap(t => 
        t.focusGroups.concat(t.locations?.flatMap(l => l.focusGroups ?? []) ?? [])
          .map(fg => fg.activities.some(a => a.data.some(d => invalidData.includes(d))) ? fg.accordionId : null)
          .filter(id => id !== null)
      ) as number[] ?? [];
      
      const locationAccordionIds = dataPeriodTabs?.flatMap(t => 
        t.locations?.map(l => 
          l.focusGroups?.some(fg => accordionsIds.includes(fg.accordionId)) ? l.id : null
        ) ?? []
      ).filter(id => id !== null) as number[] ?? [];
      
      setOpenAccordions([...accordionsIds, ...locationAccordionIds]);
      
      return false;
    }
    return true;
  }

  const filterFocusGroup = (focusGroups: FocusGroups) => {
    return focusGroups.map((focusGroup) => ({
      ...focusGroup,
      activities: focusGroup.activities.filter((activity) =>
        activity.compliances.some((item) => complianceFilters.includes(item.id)) ||
        scopeFilters.includes(activity.scopeCategory?.scopeId)
      ),
    }));
  }

  const isFinancialsIncomplete = () => {
    return ['turnover', 'costOfSales', 'balanceSheet', 'fte'].some((field) => dataPeriod?.[field as keyof DataPeriod] === undefined || !(Number(dataPeriod?.[field as keyof DataPeriod]) > 0));
  }

  const isPendingChanges = useCallback(() => {
    return updatedDataPeriod || (emissionData?.filter(e => ((!e.maxValue || (e.value ?? 0) < e.maxValue) && (e.value ?? 0) > e.minValue) || e.isEstimation).length || 0) > 0;
  }, [updatedDataPeriod, emissionData]);

  useEffect(() => {
    let updatedTabs = dataPeriodTabs || [];
    if (complianceFilters.length > 0 || scopeFilters.length > 0) {
      updatedTabs = dataPeriodTabs?.map((tab) => ({
        ...tab,
        focusGroups: filterFocusGroup(tab.focusGroups),
        locations: tab.locations?.map((location) => ({
          ...location,
          focusGroups: location.focusGroups ? filterFocusGroup(location.focusGroups) : undefined
        }))
      })) as DataPeriodTabs;
    }
    setFilteredDataPeriodTabs(updatedTabs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceFilters, scopeFilters, dataPeriodTabs]);

  // Autosave feature
  useEffect(() => {
    const interval = setInterval(() => {
      DATA_EDIT && isPendingChanges() && handleSaveDataPeriod(true);
    }, 60000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPendingChanges]);

  return (
    <section id="data-detail-page" className={className}>
      <Joyride
        callback={handleJoyrideCallback}
        run={isTourActive}
        showSkipButton={true}
        continuous={true}
        steps={DATA_COLLECTION_TABS_STEPS}
        disableScrolling={true}
        styles={TOUR_STYLES}
        showProgress={true}
      />
      <Header
        rightChildren={() => (
          <article id="actions">
            { DATA_EDIT && !dataPeriod?.isSubmitted &&
              <>
                <section
                  id="changes-indicator"
                  className={`changes-indictor ${isPendingChanges() ? 'changes-indictor--pending' : ''}`}
                  title="Auto save status"
                >
                  <span id="indicator" />
                  <p>{isPendingChanges() ? 'Edited' : 'Saved'}</p>
                </section>
                <IconButton
                  id="save-data-period"
                  title="Save"
                  onClick={() => handleSaveDataPeriod()}
                  icon={<SaveIcon variant='brand' />}
                  isLoading={loading}
                  variant='outline'
                  small
                />
                <IconButton
                  id="submit-data-period"
                  title="Save & Submit"
                  onClick={handleOnClickSubmit}
                  icon={<TickIcon />}
                  isLoading={loading}
                  label='Save and Submit'
                  smartLayout
                  small
                />
              </>
            }
          </article>
        )}
        onBackClick={handleOnBackClick}
        subtitle={DATA_COLLECTION_DATA_DETAIL_SUBTITLE}
        showBackButton
      >
        {!dataPeriod?.isSubmitted ? <span className='btn-title'>
          {title}
          <IconButton 
            icon={<BookIcon />}
            variant='ghost'
            onClick={() => { setIsTourActive(true) }}/>
        </span> : title}
      </Header>
      <main>
        {dataPeriod?.compliances && (
          <section id="quick-filters">
            { [1, 2, 3].map((scope) => (
              <ScopeTag
                key={scope}
                className="scope-tag"
                variant={scopeFilters.includes(scope) ? 'solid' : 'outline'}
                onClick={handleOnClickScopeTag(scope)}
              >
                {`Scope ${scope}`}
              </ScopeTag>
            ))}
            {dataPeriod.compliances.map((compliance) => (
              <Tag
                key={compliance.id}
                className="compliance-tag"
                onClick={handleOnClickComplianceTag(compliance)}
                variant={complianceFilters.includes(compliance.id) ? 'solid' : 'outline'}
              >
                {compliance.name}
              </Tag>
            ))}
          </section>
        )}
        {filteredDataPeriodTabs.length > 0 && (
          <Tabs activeTabId={activeTabId} onTabChange={setActiveTabId}>
            {[...[
              ...filteredDataPeriodTabs,
            ].map((tab, index) => {
              const requiredTab = (tab.locations?.length || 0) > 0 ?
                tab.locations?.some((location) => location.focusGroups?.some((focusGroup) =>
                  focusGroup.activities.some((activity) =>
                    activity.mandatory ? !activity.data?.some((item) => (item.value ?? -1) > -1) : false
                  )
                )) : tab.focusGroups.some((focusGroup) =>
                  focusGroup.activities.some((activity) =>
                    activity.mandatory ? !activity.data?.some((item) => (item.value ?? -1) > -1) : false
                ));
              const financialsRequired = submitAttempt && isFinancialsIncomplete();

              if (tab.name === "Financials") {
                return (
                  <Tab
                    id="financials"
                    key={index}
                    title={
                      <p className="tab-header">
                        Financials
                        {submitAttempt && financialsRequired && <RequiredMarker />}
                      </p>
                    }
                  >
                    <FinancialsTabContent
                      data={dataPeriod}
                      onChange={handleOnChangeDataPeriod}
                      submitAttempt={submitAttempt}
                      disabled={dataPeriod?.isSubmitted || !DATA_EDIT}
                    />
                  </Tab>
                )
              }
              return (
                <Tab
                  id="data-period-tab"
                  key={tab?.id || index}
                  title={
                    <p className="tab-header">
                      {tab.name}
                      {submitAttempt && requiredTab && <RequiredMarker />}
                    </p>
                  }
                >
                  {(tab.locations?.length || 0) > 0 || tab.name.toLowerCase().includes("location") ? (
                    <LocationsTabContent
                      data={tab.locations}
                      onChange={handleOnChangeTabs}
                      onChangeNote={handleOnChangeNotes}
                      openAccordions={openAccordions}
                      onOpenAccordionsChange={setOpenAccordions}
                      submitAttempt={submitAttempt}
                      disabled={dataPeriod?.isSubmitted || !DATA_EDIT}
                    />
                  ) : (
                    <DataPeriodTabContent
                      tabName={tab.name}
                      data={tab.focusGroups}
                      onChange={handleOnChangeTabs}
                      onChangeNote={handleOnChangeNotes}
                      openAccordions={openAccordions}
                      onOpenAccordionsChange={setOpenAccordions}
                      submitAttempt={submitAttempt}
                      disabled={dataPeriod?.isSubmitted || !DATA_EDIT}
                    />
                  )}
                </Tab>
              )
            }),
            <Tab
              id="auditing"
              title={
                <p className="tab-header audit-tab">
                  <HistoryIcon variant='dark' large/>
                </p>
              }
            >
              <AuditingTabContent
                dataPeriod={dataPeriod}
              />
            </Tab>
            ]}
          </Tabs>
        )}
      </main>
      { DATA_EDIT && (
        <SaveChangesModal
          condition={isPendingChanges()}
          onSave={handleOnClickModalSaveLeave}
        />
      )}
      {showSubmitModal && (dataPeriodTabsMandatory?.length || 0) === 0 && (
        <DataPeriodSubmitModal
          dataPeriodTabs={dataPeriodTabs}
          onClose={() => setShowSubmitModal(false)}
          onSubmit={handleOnClickModalSubmit}
          isLoading={loading}
        />
      )}
    </section>
  );
}

export default DataDetail;
