import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { createDataPeriod as createDataPeriodAPI, getDataPeriod, getTabQuestions, updateDataPeriodWithEmissionData, submitDataPeriod as submitDataPeriodAPI } from "../../api/DataPeriod";
import { useCompanyId } from "../../context";
import { ActivityData, ActivityDataItem, ActivityNote, DataPeriod, DataPeriodTabs, FocusGroups, mapDataPeriodTabs } from "../../types/DataPeriod";

const useDataPeriod = (id?: string) => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [dataPeriodId, setDataPeriodId] = useState<string | undefined>(id || paramId);
  const [dataPeriod, setDataPeriod] = useState<DataPeriod>();
  const [dataPeriodTabs, setDataPeriodTabs] = useState<DataPeriodTabs>();
  const [dataPeriodTabsMandatory] = useState<DataPeriodTabs>();
  const [emissionData, setEmissionData] = useState<ActivityData>([]);
  const [updatedDataPeriod, setUpdatedDataPeriod] = useState(false);

  const fetchApi = useCallback(async () => {
    if (dataPeriodId) {
      setLoading(true);
      Promise.all([
        await getDataPeriod(dataPeriodId, companyId)
          .then((res) => {
            setDataPeriod(res);
          }),
        await getTabQuestions(dataPeriodId, companyId)
          .then((res) => setDataPeriodTabs(res.sort((a, b) => a.order - b.order)))
      ])
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPeriodId]);

  const refreshDataPeriod = () => {
    fetchApi();
  }

  const saveDataPeriod = async (autosave: boolean = false): Promise<DataPeriodTabs | void> => {
    let id = dataPeriod?.id;
    if (dataPeriodId && dataPeriod) {
      setLoading(true);
      const filteredEmissionData = autosave ? emissionData.filter(e => (!e.maxValue || (e.value ?? 0) < e.maxValue) && (e.value ?? 0) > e.minValue) : emissionData;
      return await updateDataPeriodWithEmissionData(dataPeriodId, dataPeriod, filteredEmissionData)
        .then((res) => {
          addAlert({ id: `Data Period Saved ${id}`, type: "success", title: "Data Period Saved Successfully" });
          setUpdatedDataPeriod(false);
          setDataPeriod({
            ...dataPeriod,
            updatedAt: new Date().toUTCString()
          });
          setEmissionData([]);
          return res;
        })
        .catch((err) => {
          addAlert({ id: `Data Period Save Failed ${id}`, type: "error", title: "Data Period Save Unsuccessful", content: err.message.length ? err.message : "Data Period failed to save please try again." });
        })
        .finally(() => setLoading(false));
    }
  }

  const createDataPeriod = async (): Promise<DataPeriod | void> => {
    let id = dataPeriod?.id;
    if (dataPeriod) {
      setLoading(true);
      return await createDataPeriodAPI(companyId, dataPeriod)
        .then((res) => {
          setDataPeriodId(res.id);
          setDataPeriod(res);
          addAlert({ id: `Data Period Created ${id}`, type: "success", title: "Data Period Created Successfully" });
          setUpdatedDataPeriod(false);
          return res;
        })
        .catch(() => {
          addAlert({ id: `Data Period Create Failed ${id}`, type: "error", title: "Data Period Create Unsuccessful", content: "Data Period failed to create please try again." });
        })
        .finally(() => setLoading(false));
    }
  }

  const updateDataPeriod = (updateDataPeriod: Partial<DataPeriod>) => {
    setDataPeriod({
      ...dataPeriod,
      ...updateDataPeriod
    } as DataPeriod);
    setUpdatedDataPeriod(true);
  }

  const updateFocusGroups = (focusGroups: FocusGroups, dataItem: ActivityDataItem) => {
    return focusGroups.map((focusGroup) => {
      if (focusGroup.id === dataItem.focusGroupId) {
        return {
          ...focusGroup,
          activities: focusGroup.activities.map((activity) => {
            if (activity.id === dataItem.activityId) {
              return {
                ...activity,
                data: activity.data.map((item) => item.factorValueId === dataItem.factorValueId ? dataItem : item)
              }
            }
            return activity;
          })
        }
      }
      return focusGroup;
    });
  }

  const updateActivityNotes = (focusGroups: FocusGroups, note: ActivityNote) => {
    return focusGroups.map((focusGroup) => {
      if (focusGroup.id === note.focusGroupId) {
        return {
          ...focusGroup,
          activities: focusGroup.activities.map((activity) => {
            if (activity.id === note.activityId) {
              return {
                ...activity,
                activityNote: note,
              }
            }
            return activity;
          })
        }
      }
      return focusGroup;
    });
  }

  const updateEmissionData = (dataItem: ActivityDataItem) => {
    const newEmissions = (emissionData || [])?.filter((item) => !(item.measureId === dataItem.measureId && item.activityId === dataItem.activityId && dataItem.locationId === item.locationId));
    if(dataItem.value !== undefined || dataItem.isEstimation || dataItem.id) {
      setEmissionData([
        ...newEmissions,
        dataItem
      ]);
    } else {
      setEmissionData(newEmissions);
    }
    
    const updatedDataPeriodTabs = (dataPeriodTabs || []).map((dataPeriodTab) => {
      if (dataPeriodTab.id === dataItem.dataPeriodTabId) {
        if ((dataPeriodTab?.locations?.length || 0) > 0) {
          return {
            ...dataPeriodTab,
            locations: dataPeriodTab.locations?.map((location) => {
              if (location.id === dataItem.locationId) {
                return {
                  ...location,
                  focusGroups: location?.focusGroups && updateFocusGroups(location.focusGroups, dataItem)
                }
              }
              return location;
            })
          }
        }

        return {
          ...dataPeriodTab,
          focusGroups: updateFocusGroups(dataPeriodTab.focusGroups, dataItem)
        }
      }
      return dataPeriodTab;
    });

    setDataPeriodTabs(mapDataPeriodTabs(updatedDataPeriodTabs, Number(dataPeriodId)).sort((a, b) => a.order - b.order));
  };

  const updateNotes = (dataItem: ActivityNote) => {    
    const updatedDataPeriodTabs = (dataPeriodTabs || []).map((dataPeriodTab) => {
      if (dataPeriodTab.id === dataItem.dataPeriodTabId) {
        if ((dataPeriodTab?.locations?.length || 0) > 0) {
          return {
            ...dataPeriodTab,
            locations: dataPeriodTab.locations?.map((location) => {
              if (location.id === dataItem.locationId) {
                return {
                  ...location,
                  focusGroups: location?.focusGroups && updateActivityNotes(location.focusGroups, dataItem)
                }
              }
              return location;
            })
          }
        }

        return {
          ...dataPeriodTab,
          focusGroups: updateActivityNotes(dataPeriodTab.focusGroups, dataItem)
        }
      }
      return dataPeriodTab;
    });

    setDataPeriodTabs(updatedDataPeriodTabs);
  };

  const submitDataPeriod = async (signature: string): Promise<boolean | void> => {
    if (dataPeriodId) {
      setLoading(true);
      return submitDataPeriodAPI(dataPeriodId, signature, companyId)
        .then((res) => {
          addAlert({ id: `Data Period Submitted ${id}`, type: "success", title: "Data Period Submitted" });
          setDataPeriod({
            ...dataPeriod,
            status: 'Submitted',
            isSubmitted: true,
          } as DataPeriod);
          return res;
        })
        .catch(() => {
          addAlert({ id: `Data Period Submission Failed ${id}`, type: "error", title: "Data Period Submission Unsuccessful", content: "Data Period failed to submit please try again." });
          return false;
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (dataPeriodId) {
      fetchApi();
    }
  }, [dataPeriodId, dispatch, fetchApi]);

  useEffect(() => {
    setDataPeriodId(id || paramId);
  }, [id, paramId]);

  return {
    dataPeriod,
    dataPeriodTabs,
    dataPeriodTabsMandatory,
    emissionData,
    updatedDataPeriod,
    refreshDataPeriod,
    saveDataPeriod,
    createDataPeriod,
    updateDataPeriod,
    updateEmissionData,
    updateNotes,
    submitDataPeriod,
    loading
  };
}

export default useDataPeriod;
