import LocationNameSection from "./Sections/LocationName";
import LocationStartDateSection from "./Sections/LocationStartDate";
import LocationAddressSection from "./Sections/LocationAddress";
import LocationTypeSection from "./Sections/LocationType";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";


const getPages = (): CreateWizardPages => ([
  {
    name: 'Name',
    title: 'Location Name',
    subtitle: "Here we would like the name of the location you want to create, this is the name you will call this office for the Employee Survey and how the location is best known throughout the company.",
    children: LocationNameSection,
    isNextDisabled: (values) => values?.["name"] === undefined || values?.["name"].trim() === ''
  },
  {
    name: 'Type',
    title: 'Location Type',
    subtitle: "Please select the type of location that best describes this locations use, this should be its main use and not secondary uses a building may or may not have.",
    children: LocationTypeSection,
    isNextDisabled: (values) => values?.["type"] === undefined || values?.["type"] === ''
  },
  {
    name: 'Start Date',
    title: 'Location Start Date',
    subtitle: "This is the date you started or plan to start being in this location, if you do not know this date then please create the location once this date is known for data accuracy, as this can not be changed after the location has been created.",
    children: LocationStartDateSection,
    isNextDisabled: (values) => values?.["startDate"] === undefined || values?.["startDate"] === ''
  },
  {
    name: 'Address',
    title: 'Location Address',
    subtitle: "Please enter your location address below, this is the full address comma separated please. This can not be changed once it has been set here.",
    children: LocationAddressSection,
    isNextDisabled: (values) => values?.["address"]?.['firstLine'] === undefined || values?.["address"]?.['firstLine'].trim() === '' || values?.["address"]?.['postcode'] === undefined || values?.["address"]?.['postcode'].trim() === '',
    disabledButtonTitle: 'The first line and postcode are required to continue.',
    shouldSkip: (values) => values?.["type"] === 'Non-physical'
  }
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;