import './styles.scss';
import { Button, Modal, useAlert } from "@flotilla/component-library";
import { useState } from "react";
import { deleteDataIntegration } from '../../../../api/DataIntegration';
import { useCompanyId } from '../../../../context';
import { useCompany } from '../../../../hooks';

interface ConfirmIntegrationDeleteModalProps {
    onClose: () => void;
    onDelete: () => void;
    dataIntegrationId: number
  };

const ConfirmIntegrationDeleteModal: React.FC<ConfirmIntegrationDeleteModalProps> = ({
    onClose = () => {},
    onDelete = () => {},
    dataIntegrationId
  }) => {
    const { addAlert } = useAlert();
    const companyId = useCompanyId();
    const [company] = useCompany(companyId);
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
      setIsLoading(true);
      deleteDataIntegration(dataIntegrationId, company.id)
      .then(() => {
          addAlert({ id: `Delete Data Integration Success`, type: "success", title: "Successfully removed data integration" });
          onDelete();
      })
      .catch(() => {
          addAlert({ id: `Delete Data Integration Failed`, type: "error", title: "Failed to create data integration", content: "Failed to add a new data integration, please refresh to try again." });
      })
      .finally(() => setIsLoading(false));
    }

    return (
        <Modal
          id="delete-integration-modal"
          onClose={onClose}
        >
            <h2>Remove Data Integration</h2>
            <main>
                <p>Are you sure you wish to remove this data integration?</p>
            </main>
            <section className="button-container">
            <Button onClick={handleDelete} isLoading={isLoading}>REMOVE</Button>
            <Button variant="ghost" onClick={onClose}>
                Cancel
            </Button>
            </section>
        </Modal>);
}

export default ConfirmIntegrationDeleteModal;