import './styles.scss';

import { useState } from 'react';
import {
  Button,
  CloseIcon,
  DeleteIcon,
  IconButton,
  Select,
  Switch,
  TextArea,
  TextInput,
  useAlert,
} from '@flotilla/component-library';

import { EditorContent, EditorContentType } from '../../../../types/Report';
import { addReportPageDetail, updateReportPageComment } from '../../../../api/Report';
import { useCompanyId } from '../../../../context';

interface PopUpProps {
  openEditor?: boolean;
  onClose?: () => void;
  onSave?: (editorContent: EditorContent) => void;
  onChange?: (editorContent: EditorContent) => void;
  pageId?: number;
  contentId?: number;
  editorContent: EditorContent;
  preventColorChange?: boolean;
  newEditor?: boolean;
}

export const PopUp: React.FC<PopUpProps> = ({
  openEditor = false,
  onClose = () => ({}),
  onSave,
  onChange = () => ({}),
  pageId,
  contentId,
  editorContent,
  preventColorChange = false,
  newEditor = false,
}) => {
  const {
    header = '',
    content = [],
    boxAccent = false,
    boxAccentColor = 'dark',
    type,
  } = editorContent;
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [showHeaderInput, setShowHeaderInput] = useState(header !== null);
  const [editorHeader, setEditorHeader] = useState(header);
  const [editorType, setEditorType] = useState(type);
  const [editorBoxAccent, setEditorBoxAccent] = useState(boxAccent);
  const [editorBoxAccentColor, setEditorBoxAccentColor] =
    useState(boxAccentColor);
  const [contentArray, setContentArray] = useState(content);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleEditorTypeChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const type = event.target.value as EditorContentType;
    setEditorType(type);
  };

  const handleEditorBoxAccentColorChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = (event) => {
    setEditorBoxAccentColor(event.target.value);
  };

  const handleHeaderChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEditorHeader(event.currentTarget.value);
  };

  const handleTextAreaContentChange = (value: string, index?: number) => {
    const updatedArray = [...contentArray];
    updatedArray[index || 0] = value;
    setContentArray(updatedArray);
  };

  const handleTextTypeContentChange = (value: string) => {
    const updatedArray = value.split('\n');
    setContentArray(updatedArray);
  };

  const handleListTextDelete = (deleteIndex: number) => {
    const updatedArray = [...contentArray.filter((_, index) => index !== deleteIndex)];
    setContentArray(updatedArray);
  };

  const handleSaveClick = async () => {
    setIsSaveLoading(true);
    const content = {
      header: showHeaderInput ? editorHeader : '',
      content: contentArray,
      boxAccent: editorBoxAccent,
      boxAccentColor: editorBoxAccentColor,
      type: editorType,
    };
    if (onSave) {
      onSave(content);
    } else {
      if (contentId) {
        await updateReportPageComment(contentId, content, companyId).catch((error) => {
          addAlert({
            id: `Updating Report Page Comment ${companyId}`,
            type: 'error',
            title: 'Error Updating Report Page Comment',
            content: 'There seems to be an error when updating your report page comment, please try again.',
          });
          console.log('Update Report Comment Error: ', error);
        });
      } else if (newEditor && pageId) {
        await addReportPageDetail(companyId, pageId, content).catch((error) => {
          addAlert({
            id: `Adding Report Page Comment ${companyId}`,
            type: 'error',
            title: 'Error Adding Report Page Comment',
            content: 'There seems to be an error when adding your report page comment, please try again.',
          });
          console.log('Add Report Comment Error: ', error);
        });
      }  
    }
    onChange({
      ...editorContent,
      header: showHeaderInput ? editorHeader : '',
      content: contentArray,
      boxAccent: editorBoxAccent,
      boxAccentColor: editorBoxAccentColor,
      type: editorType,
    });
    setIsSaveLoading(false);
    onClose();
  };

  return (
    <div
      className={`editor-container editor-container--${
        openEditor ? 'open' : 'closed'
      }`}
    >
      <div id="report-editor-popup">
        <h2>Report Content Editor</h2>
        <IconButton
          className="close-icon"
          variant="ghost"
          aria-label="Close Icon"
          icon={<CloseIcon variant='dark' />}
          onClick={onClose}
        />
        <main>
          <div className="switch-input-container">
            <div id="header-switch-container" className="switch-container">
              <p>Header</p>
              <Switch
                onToggle={() => setShowHeaderInput(!showHeaderInput)}
                checked={showHeaderInput}
              />
            </div>
            {showHeaderInput && (
              <TextInput
                id="header-input"
                value={editorHeader}
                onChange={handleHeaderChange}
              />
            )}
          </div>
          <Select
            id="editor-type-selector"
            label="Edit Type"
            onChange={handleEditorTypeChange}
            value={editorType}
          >
            <option value="text">Text</option>
            <option value="ordered-list">Ordered List</option>
            <option value="unordered-list">Unordered List</option>
          </Select>
          {!preventColorChange &&
            ['text', 'unordered-list'].includes(editorType || '') && (
              <div className="switch-input-container">
                {['text'].includes(editorType || '') && (
                  <div className="switch-container">
                    <p>Border Accent</p>
                    <Switch
                      onToggle={() => setEditorBoxAccent(!editorBoxAccent)}
                      checked={editorBoxAccent}
                    />
                  </div>
                )}
                {(['unordered-list'].includes(editorType || '') ||
                  editorBoxAccent) && (
                  <Select
                    id="editor-accent-color-selector"
                    label="Color"
                    onChange={handleEditorBoxAccentColorChange}
                    value={editorBoxAccentColor}
                  >
                    <option value="orange">Orange</option>
                    <option value="dark">Dark</option>
                  </Select>
                )}
              </div>
            )}
          {['ordered-list', 'unordered-list'].includes(editorType || '') ? (
            <>
              {contentArray?.map((contentItem, index) => (
                <section
                  key={index}
                  className='list-text-input-container'
                >
                  <header>
                    <h4>List Item {index + 1}</h4>
                    <IconButton
                      variant='ghost'
                      icon={<DeleteIcon variant='dark'/>}
                      onClick={() => handleListTextDelete(index)}
                    />
                  </header>
                  <TextArea
                    id={`${index}`}
                    value={contentItem}
                    onChange={(event) =>
                      handleTextAreaContentChange(
                        event.currentTarget.value,
                        index
                      )
                    }
                  />
                </section>
              ))}
              <Button
                onClick={() => setContentArray([...contentArray, ''])}
                variant="outline"
              >
                Add
              </Button>
            </>
          ) : (
            <TextArea
              id="text-editor-text-area"
              value={contentArray?.join('\n')}
              onChange={(event) =>
                handleTextTypeContentChange(event.currentTarget.value)
              }
            />
          )}
        </main>
        <Button id="editor-save-button" onClick={handleSaveClick} isLoading={isSaveLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};
