import './styles.scss';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from '@flotilla/component-library';

import { getOnboardingWizardPages } from '../../../assets/content/CreateWizard';
import FinalSection from '../../../assets/content/CreateWizard/Onboarding/Sections/Final';
import useCompanySettings from '../../../hooks/Company/useCompanySettings';
import { updateCompanyAndSettings } from '../../../api/CompanySettings';
import { useCompany } from '../../../hooks';
import { useCompanyId } from '../../../context';

interface OnboardingProps {
  className?: string;
};

const Onboarding: FC<OnboardingProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [
    company,
    ,
    ,
    ,
    editCompany,
   ] = useCompany(companyId);
  const {
    companySettings,
    updateCompanySettings,
    loading
  } = useCompanySettings();

  const handleOnComplete = () => {
    companySettings && updateCompanyAndSettings(
      company,
      companySettings
    )
      .then(() => navigate('/', { replace: true }));
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    if (values['url']) {
      editCompany(values);
    } else {
      updateCompanySettings(values);
    }
  }

  return (
    <Wizard
      className={className}
      pages={getOnboardingWizardPages()}
      values={{
        ...companySettings,
        url: company?.url || ''
      } as { [key: string]: unknown; } | undefined}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Company Setup',
        subtitle: 'Welcome to your Company Setup Wizard, this Wizard will help set up your new Company for Flotilla!'
      }}
      endPage={{
        name: 'Final',
        title: 'Company Setup',
        subtitle: 'Check the information below, you are one step from completing your company setup.',
        children: (
          <FinalSection
            values={{
              ...companySettings,
              url: company?.url || ''
            }}
          />
        ),
      }}
      submitLoading={loading}
      onComplete={handleOnComplete}
    />
  );
}

export default Onboarding;
