import { useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { CreateReport } from "../../types/Report";
import { createReport as createReportAPI } from "../../api/Report";
import { useCompanyId } from "../../context";

const useCreateReport = () => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const [createReport, setCreateReport] = useState<CreateReport>();

  const updateCreateReport = (updatedCreateReport: Partial<CreateReport>) => {
    setCreateReport({
      ...createReport,
      ...updatedCreateReport
    });
  };

  const saveCreateReport = async (): Promise<string | void> => {
    if (createReport) {
      setLoading(true);
      return await createReportAPI({...createReport, baselineYear: parseInt(createReport.baselineYear?.toString().split(' ')[0] ?? "")})
        .then((res) => {
          addAlert({ id: `Report Created ${companyId}`, type: "success", title: "Report Created Successfully" });
          return res as string;
        })
        .catch(() => {
          addAlert({ id: `Report Create Failed ${companyId}`, type: "error", title: "Report Create Unsuccessful", content: "Report failed to create please try again." });
        })
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    updateCreateReport({
      companyId: companyId
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return {
    createReport,
    updateCreateReport,
    saveCreateReport,
    loading
  };
}

export default useCreateReport;

