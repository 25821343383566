import './styles.scss';

import React, { ChangeEventHandler, useState } from 'react';
import { Modal, Button, TextInput, Alert } from '@flotilla/component-library';

import { useAppSelector } from '../../../helpers/hooks';
import { getName } from '../../../reducers/user';
import { DataPeriodTabs, FocusGroups } from '../../../types/DataPeriod';

interface DataPeriodSubmitModalProps {
  className?: string;
  dataPeriodTabs?: DataPeriodTabs;
  onSubmit?: (signature: string) => void;
  onClose?: () => void;
  isLoading?: boolean;
};

const DataPeriodSubmitModal: React.FC<DataPeriodSubmitModalProps> = ({
  className = "",
  dataPeriodTabs,
  onSubmit = () => ({}),
  onClose = () => ({}),
  isLoading = false,
}) => {
  const username = useAppSelector(getName);
  const [signature, setSignature] = useState<string>();

  const handleOnClickModalSubmit = () => {
    if (signature) {
      onSubmit(signature);
    }
  }

  const handleOnChangeSignature: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSignature(event.currentTarget.value);
  }

  const getFocusGroupContent = (focusGroups: FocusGroups) => {
    return (
      <>
        {focusGroups.map((focusGroup) => {
          if (focusGroup.completed) return null;
          return (
            <React.Fragment key={focusGroup.name}>
              <li key={focusGroup.name}>{focusGroup.name}</li>
              <ul>
                {focusGroup.activities.map((activity) => {
                  if (activity.completed) return null;
                  return <li key={activity.name}>{activity.name}</li>
                })}
              </ul>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  return (
    <Modal id="submit-modal" className={className} onClose={onClose}>
      <header>
        <h4>Are you sure you want to submit this data period?</h4>
        <p>Once submitted you can no longer make changes to this data period. Any alterations required post submission may incur an administration fee.</p>
      </header>
      <TextInput
        id="submit-sign-off"
        label={`Sign off by entering your ${username.includes('@') ? 'email address' : 'full name'}: ${username}`}
        placeholder={`Enter your ${username.includes('@') ? 'email address' : 'full name'}`}
        aria-required={true}
        value={signature}
        onChange={handleOnChangeSignature}
      />
      {dataPeriodTabs?.some((tab) => !tab.completed) && (
        <Alert
          id="warning-notes"
          type='warning'
          title="Please note, some non-mandatory items have not been completed. Click to check those items"
          accordionContent
          content={
            <section id="notes">
              {dataPeriodTabs?.map((tab) => {
                if (tab.completed) return null;
                return (
                  <React.Fragment key={tab.name}>
                    <p>{tab.name}</p>
                    <ul>
                      { getFocusGroupContent(tab.focusGroups) }
                      {tab.locations?.map((location) => {
                        if (location.completed) return null;
                        return (
                          <React.Fragment key={location.name}>
                            <li>{location.name}</li>
                            <ul>
                              { getFocusGroupContent(location.focusGroups || [])}
                            </ul>
                          </React.Fragment>
                        )
                      })}
                    </ul>
                  </React.Fragment>
                )
              })}
            </section>
          }
        />
      )}
      <Alert
        id="info-alert"
        type='info'
        title="The GHG emissions displayed following submission are preliminary and may change after consultant review. The final footprint, including employee impacts, will be accessible when your report is complete."
      />
      <section id="actions">
        <Button variant='ghost' onClick={onClose} isLoading={isLoading}>Cancel</Button>
        <Button onClick={handleOnClickModalSubmit} disabled={signature !== username} isLoading={isLoading}>Submit</Button>
      </section>
    </Modal>
  );
}

export default DataPeriodSubmitModal;
