import { API_LOCATION } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { formatCompanyId, formatCompanyIdQueryString } from "../../helpers/formatCompanyId";
import { Location, Locations, getLocationTypeId, mapLocations } from "../../types/Location";
import { QuestionGroups, Submissions, mapQuestionGroups } from "../../types/Question";
import { dedupeFetch } from "../dedupeFetch";

export const getLocations = (id: number): Promise<Locations> => {
  return dedupeFetch(`${API_LOCATION}/GetAll?${formatCompanyIdQueryString(id)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapLocations(res.payload));
}

export const getLocation = (id: number, companyId: string | number): Promise<Location> => {
  return fetch(`${API_LOCATION}/Get?locationId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapLocations([res.payload])[0]);
}

export const updateLocation = (location: Location): Promise<Location> => {
  return fetch(`${API_LOCATION}/Update`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'PUT',
    body: JSON.stringify({
      ...location,
      locationId: location.id,
      type: getLocationTypeId(location.type),
      colourHex: location.colorHex,
      companyId: formatCompanyId(location.companyId),
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapLocations([res.payload])[0]);
}

export const createLocation = (id: string, location: Location): Promise<Location> => {
  return fetch(`${API_LOCATION}/Create`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      ...location,
      type: getLocationTypeId(location.type),
      companyId: formatCompanyId(id),
      add1: location.address?.firstLine || '',
      add2: location.address?.secondLine || '',
      add3: location.address?.thirdLine || '',
      country: location.address?.country || '',
      postcode: location.address?.postcode || '',
      colourHex: ''
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => {
      return res.payload as Location;
    });
}

export const getLocationQuestions = (id: string, companyId: string | number): Promise<QuestionGroups> => {
  return fetch(`${API_LOCATION}/GetQuestions?locationId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => mapQuestionGroups(res.payload));
}

export const submitLocationQuestionAnswers = (id: string, submissions: Submissions = [], companyId: string | number): Promise<boolean> => {
  return fetch(`${API_LOCATION}/SubmitQuestionAnswers`, {
    headers: {
      "X-CSRF": "1",
      "Content-Type": "application/json"
    },
    method: 'POST',
    body: JSON.stringify({
      companyId: formatCompanyId(companyId),
      locationId: Number(id),
      questionAnswers: submissions.map((submission) => ({
        questionAnswerId: submission.locationQuestionAnswerId,
        freeTextAnswer: submission.freeTextAnswer
      })),
    }),
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.success);
}

export const deleteLocation = (id: string | number, companyId: string | number) => {
  return fetch(`${API_LOCATION}/Delete?locationId=${id}&${formatCompanyIdQueryString(companyId)}`, {
    headers: { "X-CSRF": "1" },
    method: 'DELETE',
  })
    .then((res) => checkAuthorised(res))
    .then(checkErrors)
    .then((res) => res.success);
}