import './styles.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AddIcon,
  Button,
  GenericData,
  Header,
  IconButton,
  SearchFilter,
  type Header as HeaderType
} from '@flotilla/component-library';

import { ReactComponent as BoardIcon } from '../../../../assets/icons/Board.svg';
import { ReactComponent as GanttIcon } from '../../../../assets/icons/Gantt.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/List.svg';
import ActionGantt from '../../../../components/ActionGantt';
import { useAppSelector } from '../../../../helpers/hooks';
import useNetZeroPlan from '../../../../hooks/NetZeroPlan/useNetZeroPlan';
import { getNetZeroPlanId } from '../../../../reducers/user';
import { Action, Actions, impactAreaValues, potentialType } from '../../../../types/Action';
import BoardView from './Board';
import TableView from './Table';
import { PROJECT_PLAN_SUBTITLE } from '../../../../assets/content/Subtitles';
import HeaderComponent from '../../../../components/HeaderV2';
import useCachedFilters from '../../../../hooks/PageCaching/useCachedFilters';
import { useUserAccess } from '../../../../context';

const headers = [
  {
    key: "title",
    label: "Name",
    fixed: true
  },
  {
    key: "status",
    label: "Status"
  },
  {
    key: "scopeId",
    label: "Scope",
    optional: true
  },
  {
    key: "defaultPriority",
    label: "Priority",
    type: "number"
  },
  {
    key: "startDate",
    label: "Start Date",
    type: "date"
  },
  {
    key: "targetCompletionDate",
    label: "Target Date",
    type: "date"
  },
  {
    key: "estReduction",
    label: "Estimated Reduction",
    type: "number",
    initialDescSort: true
  },
  {
    key: "estimatedCost",
    label: "Est. Spend",
    type: "number",
    initialDescSort: true
  },
  {
    key: "estimatedROI",
    label: "Est. ROI",
    optional: true
  },
  {
    key: "estimatedEffort",
    label: "Est. Effort",
    optional: true
  },
  {
    key: "departmentsString",
    label: "Departments",
    optional: true
  },
  {
    key: "costSavingsPotential",
    label: "Cost Savings",
    optional: true
  },
  {
    key: "carbonSavingsPotential",
    label: "Carbon Savings",
    optional: true
  },
  {
    key: "coBenefitsPotential",
    label: "Co-Benefits",
    optional: true
  },
  {
    key: "quickWin",
    label: "Quick Win",
    optional: true
  },
  {
    key: "impactAreaName",
    label: "Impact Area",
    optional: true
  },
  {
    key: "focusGroupName",
    label: "Focus Group",
    optional: true
  },
  {
    key: "assignedTo",
    label: "Assigned To",
    optional: true
  },
  {
    key: "actionTagsString",
    label: "Tags",
    optional: true
  },
] as HeaderType[];

interface ProjectPlanProps {
  className?: string;
};

const ProjectPlan: React.FC<ProjectPlanProps> = ({
  className = "",
}) => {
  const { userAccess } = useUserAccess();
  const { NET_ZERO_PLAN_EDIT } = userAccess;
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const location = useLocation();
  const netZeroPlanId = useAppSelector(getNetZeroPlanId);
  const [plan, setPlanId, , loading] = useNetZeroPlan(netZeroPlanId);
  const [actions, setActions] = useState<Actions>();
  const [headersOverride, setHeadersOverride] = useState<Header[]>();
  const { filterData, onFiltersChange } = useCachedFilters("ProjectPlanFilters");

  useEffect(() => {
    plan?.actions && setActions(plan.actions);
  }, [plan]);

  useEffect(() => {
    netZeroPlanId && setPlanId(netZeroPlanId);
  }, [netZeroPlanId, setPlanId]);

  const handleAddGoalActionClick = () => {
    navigate('../actionlibrary');
  }

  const handleClickAction = (action: Action, view: string) => {
    const newLocation = location;
    newLocation.hash = view;
    navigate(newLocation, { replace: true });
    navigate(`./${action.id}`);
  }

  const handleActionChange = (action: Action) => {
    setActions(actions?.map((item) => {
      if (item.id === action.id) {
        return action;
      }
      return item;
    }));
  }

  const handleOnColumnsChange = (newHeaders: Header[]) => {
    setHeadersOverride([...newHeaders]);
  }

  return (
    <section id="project-plan-page" className={className}>
      <HeaderComponent
        subtitle={PROJECT_PLAN_SUBTITLE}
        rightChildren={() =>
          <section id="extra-content">
            {
              (plan?.actions?.length || 0) > 0 && (
                <article id="actions-completed-container">
                  <h3>{actions?.filter((value) => value.status === "Completed").length || 0}/{actions?.length || 0}</h3>
                  <p>Actions completed</p>
                </article>
              )
            }
            { NET_ZERO_PLAN_EDIT && (
              <IconButton
                id="add-goal-action"
                isLoading={loading || netZeroPlanId === undefined}
                icon={<AddIcon />}
                onClick={handleAddGoalActionClick}
              />
            )}
          </section>
        }
      >
          Project Plan
      </HeaderComponent>
      <main>
        <SearchFilter
          key={!loading && (actions?.length || 0) > 0 ? 'project-plan' : ''}
          id="view"
          instanceName={"ProjectPlan"}
          className={className}
          data={actions as unknown as GenericData}
          onFiltersChange={onFiltersChange}
          quickFilters={filterData?.quickFilters || [
            {
              key: 'impactAreaName',
              label: 'Impact Area',
              selectedOptions: queryParams.get("impactArea") ? [queryParams.get("impactArea") || ''] : undefined,
              sort: (a, b) => impactAreaValues.indexOf(a) - impactAreaValues.indexOf(b),
            },
            {
              key: 'scopeName',
              label: 'Scope',
              options: ['Scope 1', 'Scope 2', 'Scope 3'],
              addLabel: true,
            }
          ]}
          filters={filterData?.filters || [
            {
              key: "myAction",
              label: "Show my Actions",
              value: "My Actions",
              type: "boolean"
            },
            {
              key: "companyId",
              label: "Custom Actions",
              value: "Custom Actions",
              type: "boolean"
            },
            {
              key: "quickWin",
              label: "Quick Wins",
              value: "Quick Wins",
              type: "boolean"
            },
            {
              key: "employeeSuggested",
              label: "Employee Suggested",
              value: "Employee Suggested",
              type: "boolean"
            },
            {
              key: "status",
              label: "Status",
              selectedOptions: queryParams.get("status") ? [queryParams.get("status")] : undefined
            },
            {
              key: "tags",
              label: "Tags",
              isArray: true
            },
            {
              key: "actionTags",
              label: "Action Tag",
              isArray: true
            },
            {
              key: "departmentNames",
              label: "Departments",
              isArray: true,
              selectedOptions: queryParams.get("department") ? [queryParams.get("department")]: undefined
            },
            {
              key: "defaultPriority",
              label: "Priority",
              addLabel: true
            },
            {
              key: "costSavingsPotential",
              label: "Cost Savings",
              sort: (a, b) => potentialType.indexOf(a) - potentialType.indexOf(b)
            },
            {
              key: "carbonSavingsPotential",
              label: "Carbon Savings",
              sort: (a, b) => potentialType.indexOf(a) - potentialType.indexOf(b)
            },
            {
              key: "coBenefitsPotential",
              label: "Corporate Co-Benefits",
              sort: (a, b) => potentialType.indexOf(a) - potentialType.indexOf(b)
            },
            {
              key: "assignedTo",
              label: "Assigned to"
            },
            {
              key: "sdgNames",
              label: "Sustainable Development Goals",
              isArray: true
            },
            {
              key: "scopeCategoryNames",
              label: "Scope Category",
              isArray: true
            },
          ]}
          selectedView={location.hash.replace('#', '') || undefined}
          views={[
            {
              key: "board",
              label: "Board",
              icon: <BoardIcon />,
              children: (data) => (actions?.length || 0) < 1 && !loading ? (
                <section id="no-actions-comment">
                  <h2>No Actions in your Plan</h2>
                  <p>Currently there are no Actions in your plan, please try adding them from the Action Library</p>
                  <Button onClick={() => navigate('../actionlibrary')}>
                    Action Library
                  </Button>
                </section>
              ) : (
                <BoardView
                  className="image-download-content"
                  data={data as unknown as Actions}
                  onClick={(action) => handleClickAction(action, 'board')}
                  loading={loading}
                  onChange={handleActionChange}
                />
              )
            },
            {
              key: "list",
              label: "List",
              type: "table",
              icon: <ListIcon />,
              children: (data) => (
                <TableView
                  data={data as unknown as Actions}
                  onClick={(action) => handleClickAction(action, 'list')}
                  loading={loading || !headersOverride}
                  headers={headersOverride || headers}
                />
              )
            },
            {
              key: "gantt",
              label: "Gantt",
              icon: <GanttIcon />,
              children: (data) => data?.length > 0 ? (
                <ActionGantt
                  actions={data as unknown as Actions}
                  onClick={(action) => handleClickAction(action as unknown as Action, 'gantt')}
                />
              ) : (
                <section id="no-actions-comment">
                  {
                    (actions?.length || 0) > 0 ? (
                      <>
                        <h2>No Actions meet this criteria</h2>
                        <p>Currently there are no Actions in your plan that meet the filters set.</p>
                      </>
                    ) : (
                      <>
                        <h2>No Actions in your Plan</h2>
                        <p>Currently there are no Actions in your plan, please try adding them from the Action Library</p>
                      </>
                    )
                  }
                  <Button onClick={() => navigate('../actionlibrary')}>
                    Action Library
                  </Button>
                </section>
              )
            }
          ]}
          headers={headers}
          onColumnsChange={handleOnColumnsChange}
          canDownloadImage
          downloadImageSelector='.image-download-content'
        />
      </main>
    </section>
  );
}

export default ProjectPlan;
