import { mapActions } from "./Action";
import { ImpactAreaGroupType, getFakeImpactAreas } from "./ImpactArea";
import { OfficeMeasures, mapOfficeMeasures } from "./OfficeMeasure";

export const mapActivitiesV2 = (data: {[key: string]: unknown}[], impactArea?: string, focusGroup?: string): Activities => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    statusCount: item.statusCounts,
    actionsTotal: item.actions?.length,
    tco2e: item.tcO2e,
    impactArea: impactArea,
    focusGroup: focusGroup,
    activity: item.name,
    scope: item.scopeId,
    ...(item?.actions ? {actions: mapActions(item.actions as {[key: string]: unknown}[])} : {}),
    ...(item?.officeMeasures ? {children: mapOfficeMeasures(item.officeMeasures as {[key: string]: unknown}[], impactArea, focusGroup, item.name, item.scopeId)} : {}),
  })) as unknown[] as Activities;
}

export const mapActivities = (data: {[key: string]: unknown}[], impactArea?: string, focusGroup?: string): Activities => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => {
    const mappedOfficeMeasures = item?.officeMeasures ? mapOfficeMeasures(item.officeMeasures as {[key: string]: unknown}[], impactArea, focusGroup, item.name, item.scopeId) : [];
    const noneOfficeMeasures = (mappedOfficeMeasures?.length || 0) === 1 && mappedOfficeMeasures?.[0]?.locationId === null;

    return {
      ...item,
      statusCount: item.statusCounts,
      actionsTotal: item.actions?.length,
      tco2e: item.tcO2e,
      impactArea: impactArea,
      focusGroup: focusGroup,
      activity: item.name,
      scope: item.scopeId,
      ...(item?.actions ? {actions: mapActions(item.actions as {[key: string]: unknown}[])} : {}),
      ...((mappedOfficeMeasures?.length || 0) > 0 ?
        noneOfficeMeasures ?
        {
          ...mappedOfficeMeasures?.[0],
          name: item.name
         } : 
        {children: mappedOfficeMeasures} :
        {}
      ),
    }
  }) as unknown[] as Activities;
}

export const getFakeActivities = (): Activities => {
  return getFakeImpactAreas().map((item, index) => ({
    ...item,
    scope: index
  }))
}

export const getFakeEmissionHotspots = (): Activities => {
  return [...getFakeImpactAreas(), ...getFakeImpactAreas()].map((item, index) => ({
    ...item,
    scope: index
  }))
}

export interface Activity extends ImpactAreaGroupType {
  scope: number,
  focusGroupId?: number,
  focusGroup?: any,
  officeMeasures?: OfficeMeasures;
}

export type Activities = Activity[];