import React from 'react';

import { Route, Routes } from 'react-router-dom';

import ReportCreate from './Create';
import DataCollectionUpload from '../DataCollectionUpload';
import ReportDetail from './Detail';
import ReportsList from './List';
import { useUserAccess } from '../../../context';

const CarbonReports: React.FC = () => {
  const { userAccess } = useUserAccess();
  const { REPORT_CREATE, REPORT_EDIT } = userAccess;
  return (
    <Routes>
      <Route index element={<ReportsList />} />
      { REPORT_CREATE && (
        <Route path="create" element={<ReportCreate />} />
      )}
      { REPORT_EDIT && (
        <>
          <Route path="upload" element={<DataCollectionUpload />} />
          <Route path=":reportId" element={<ReportDetail />} />
        </>
      )}
    </Routes>
  );
}

export default CarbonReports;
