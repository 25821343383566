import './styles.scss';

import React, { ChangeEventHandler } from 'react';
import { Header, Tag, TextInput } from '@flotilla/component-library';

import ImpactAreaTag from '../../../../../../components/ImpactAreaTag';
import { Action } from '../../../../../../types/Action';
import getNZPTableCell from '../../../../../../helpers/getNZPTableCell';
import QuickWinTag from '../../../../../../components/QuickWinTag';
import EmployeeSuggestedTag from '../../../../../../components/EmployeeSuggestedTag';
import useNetZeroPlanAction from '../../../../../../hooks/NetZeroPlan/useNetZeroPlanAction';
import CustomActionTag from '../../../../../../components/CustomActionTag';

interface TableRowProps {
  data: Action;
  onClick?: React.MouseEventHandler<HTMLElement>;
  headers?: Header[];
};

const TableRow: React.FC<TableRowProps> = ({
  data,
  onClick = () => ({}),
  headers = []
}) => {
  const {
    action,
    updateAction,
    saveAction,
    loading
  } = useNetZeroPlanAction(data);
  const {
    id,
    title,
    impactArea,
    activityName,
    actionTags,
    quickWin,
    employeeSuggested,
    companyId
  } = action;

  const handleOnChange = (key: string): ChangeEventHandler<HTMLInputElement> => {
    return (event) => {
      updateAction({
        [key]: event.currentTarget.value
      });
    }
  }

  const handleOnBlur = () => {
    saveAction();
  }

  return (
    <tr key={id} id={`project-plan-item--${id}`} className="project-plan-item">
      {headers?.map((h, index) => {
        switch (h.key) {
          case "title":
            return (
              <td key={index} id="name" className={`action--${impactArea?.name.toLowerCase().replaceAll(' ', '-')}`}>
                <article onClick={onClick}>
                  <p>{title}</p>
                  <section id="tag-container">
                    {companyId && <CustomActionTag />}
                    {quickWin && <QuickWinTag />}
                    {employeeSuggested && <EmployeeSuggestedTag />}
                    {impactArea &&
                      <ImpactAreaTag size="small" extraText={activityName}>
                        {impactArea.name}
                      </ImpactAreaTag>
                    }
                    { actionTags && actionTags.map((value, index) => (
                      <Tag key={index} className="tag" size="small" title={value}>{value}</Tag>
                    ))}
                  </section>
                </article>
              </td>
            );
          case "startDate":
          case "targetCompletionDate":
            return (
              <td key={h.key}>
                <TextInput
                  id={h.key}
                  className="date-input"
                  value={action[h.key]?.split("T")[0]}
                  onChange={handleOnChange(h.key)}
                  onBlur={handleOnBlur}
                  disabled={loading}
                  type="date"
                />
              </td>
            )
          default:
            return getNZPTableCell(h.key, action);
        }
      })}
    </tr>
  );
}

export default TableRow;
