import NetZeroYearSection from "./Sections/NetZeroYear";
import ReductionTargetSection from "./Sections/ReductionTarget";
import { TARGETS_GUIDANCE_SUBTITLE } from "../../Subtitles";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";

const getPages = (hasReportData: boolean = false): CreateWizardPages => ([
  {
    name: 'Net Zero Year',
    title: 'Net Zero Year',
    subtitle: `Here we would like you to set your Net Zero Year, this is the year you think you will be best able to achieve Net Zero by.${!hasReportData ? " Don't worry! If you're not sure what date to select right now, you can always change it later. However, we need you to select a date so that you can create and add actions to your plan." : ''}`,
    children: NetZeroYearSection,
    isNextDisabled: (values) => values?.["completionDate"] === undefined || values?.["completionDate"] === ''
  },
  hasReportData && {
    name: 'Reduction Target',
    title: 'Reduction Target',
    subtitle: TARGETS_GUIDANCE_SUBTITLE,
    children: ReductionTargetSection
  },
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;