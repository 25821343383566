import './styles.scss';

import { FC, Fragment, useEffect } from 'react';
import { Divider, TickIcon } from '@flotilla/component-library';

import useCompanyTasks from '../../../hooks/CompanyTask';
import { CompanyTask } from '../../../types/CompanyTask';

export type CompanyTasksViewProps = {
  className?: string
};

const CompanyTasksView: FC<CompanyTasksViewProps> = ({ className = '' }) => {
  const { companyTasks, refreshCompanyTasks } = useCompanyTasks();

  useEffect(() => {
    refreshCompanyTasks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickItem = (task: CompanyTask) => {
    return () => {
      const pathnames = window.location.pathname.split('/');
      if (Number(pathnames?.[1])) {
        window.location.href = `/${pathnames?.[1]}/${task.navLink}`;
      } else {
        window.location.href = `/${task.navLink}`;
      }
    }
  }

  return (
    <article className={`company-tasks-view ${className}`}>
      <header>
        <h4>Tasks</h4>
        <p>{companyTasks?.filter((item) => item.completed).length || 0} of {companyTasks?.length || 0} completed</p>
      </header>
      <main>
        { companyTasks?.map((item, index) => (
          <Fragment key={item.id}>
            <section className={`company-task-item ${item.completed ? 'company-task-item--completed' : ''}`} onClick={handleOnClickItem(item)}>
              <section className="main-info">
                <p id="title">{item.title}</p>
                <p id="description" title={item.description}>{item.description}</p>
                {item.readMoreLink && (
                  <a href={item.readMoreLink} target="_blank" rel="noreferrer">See more</a>
                )}
              </section>
              { item.completed && (
                <span id="completed">
                  <TickIcon variant='light' />
                </span>
              )}
            </section>
            { index < (companyTasks.length - 1) && (
              <Divider />
            )}
          </Fragment>
        ))}
      </main>
    </article>
  );
};

export default CompanyTasksView;
