import './styles.scss';

import React from 'react';
import { GenericData, Table, Tooltip } from '@flotilla/component-library';

import { Action, Actions } from '../../../../../types/Action';
import getRatingIcon from '../../../../../helpers/getRatingsIcon';
import ImpactAreaTag from '../../../../../components/ImpactAreaTag';
import ScopeTag from '../../../../../components/ScopeTag';
import CopyButton from '../../../../../components/CopyButton';

interface TableViewProps {
  className?: string;
  data?: Actions;
  loading?: boolean;
  onClick?: (action: Action) => void;
};

const TableView: React.FC<TableViewProps> = ({
  className = "",
  data,
  onClick = () => ({}),
  loading = false
}) => {
  const handleClickAction = (action: Action): React.MouseEventHandler<HTMLTableRowElement> => {
    return (event) => {
      event.stopPropagation();
      onClick(action);
    }
  }
  
  return (
    <section id="table-view" className={className}>
      <Table
        isLoading={loading}
        stickyHeader
        dataTable
        headers={[
          {
            key: "title",
            label: "Name"
          },
          {
            key: "scopeId",
            label: "Scope"
          },
          {
            key: "departmentsString",
            label: "Departments"
          },
          {
            key: "companiesUsingActionCount",
            label: "# Customers"
          },
          {
            key: "defaultPriority",
            label: "Priority"
          },
          {
            key: "costSavingsPotential",
            label: "Cost Savings"
          },
          {
            key: "carbonSavingsPotential",
            label: "Carbon Savings"
          },
          {
            key: "coBenefitsPotential",
            label: "Co-Benefits"
          },
          {
            key: "avoidancePercent",
            label: "Estimated Reduction %",
            type: "number",
            initialDescSort: true
          },
        ]}
        data={data as unknown as GenericData}
        rowComponent={(item) => {
          const {
            title,
            impactArea: impactAreaObject,
            departmentsString,
            defaultPriority,
            activityName,
            costSavingsPotential,
            carbonSavingsPotential,
            coBenefitsPotential,
            avoidancePercent,
            netZeroPlanAction,
            scopeId,
            companiesUsingAction
          } = item as unknown as Action;
          const impactArea = impactAreaObject?.name;
          return (
            <tr>
              <td id="name" className={`action--${impactArea?.toLowerCase().replaceAll(' ', '-')}`}>
                <article onClick={handleClickAction(item as unknown as Action)}>
                  <p>{title}</p>
                  {
                    impactArea &&
                      <ImpactAreaTag
                        size="small"
                        extraText={activityName}
                      >
                        {impactArea}
                      </ImpactAreaTag>
                  }
                </article>
              </td>
              <td>{scopeId ? <ScopeTag size="small">{`Scope ${scopeId}`}</ScopeTag>: <></>}</td>
              <td>{departmentsString}</td>
              <td className="customers">
                { companiesUsingAction?.length ? <Tooltip 
                  hideCloseButton 
                  icon={() => <>{companiesUsingAction?.length ?? 0}</>}>
                    <CopyButton text={companiesUsingAction?.join("\r\n")}/>
                    {companiesUsingAction.map(c => <p key={c}>{c}</p>)}
                </Tooltip> : <p>0</p>}
              </td>
              <td>{defaultPriority}</td>
              <td className="rating">
                { getRatingIcon(costSavingsPotential, "money") }
              </td>
              <td className="rating">
                { getRatingIcon(carbonSavingsPotential, "cloud") }
              </td>
              <td className="rating">
                { getRatingIcon(coBenefitsPotential, "hand") }
              </td>
              <td>{netZeroPlanAction?.estimatedReductionPercent ?? avoidancePercent}</td>
            </tr>
          )
        }}
      />
    </section>
  );
}

export default TableView;
