import './styles.scss';

import React from 'react';

import { reportTypes } from '../../../../../../types/Report';
import { SectionProps } from '../../../../../../types/CreateWizard';

const FinalSection: React.FC<SectionProps> = ({
  className = "",
  values
}) => {
  return (
    <section id="report-final-section" className={className}>
      <article className="stat">
        <p>Report Title</p>
        <h3>{values?.['title']}</h3>
      </article>
      <article className="stat">
        <p>Report Type</p>
        <h3>{reportTypes[values?.['reportType']]}</h3>
      </article>
      <article className="stat">
        <p>Report Baseline Year</p>
        <h3>{values?.['baselineYear']}</h3>
      </article>
    </section>
  );
}

export default FinalSection;
