import { useCallback, useEffect, useState } from "react";
import { useAlert } from "@flotilla/component-library";

import { useAppDispatch } from "../../helpers/hooks";
import { useIdentityCompanyId } from "../../context";
import { Users } from "../../types/User";
import { getUsers } from "../../api/User";

const useUsers = () => {
  const { addAlert } = useAlert();
  const dispatch = useAppDispatch();
  const identityCompanyId = useIdentityCompanyId();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<Users>();

  const fetchApi = useCallback(() => {
    setLoading(true);
    setUsers([]);
    getUsers(identityCompanyId)
      .then(setUsers)
      .catch((error) => {
        addAlert({
          id: "Get Users Error",
          type: 'error',
          title: 'Error getting Users',
          content: 'We are sorry there seems to be an issue getting the Users. Please try again.',
        });
        console.log("Error while getting Users: ", error);
      })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, identityCompanyId]);

  const updateUsers = () => {
    fetchApi();
  }

  useEffect(() => {
    fetchApi();
  }, [dispatch, fetchApi, identityCompanyId]);

  return {
    users,
    updateUsers,
    loading
  };
}

export default useUsers;
