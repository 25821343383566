import './styles.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from '@flotilla/component-library';

import { getDataPeriodWizardPages } from '../../../../assets/content/CreateWizard';
import FinalSection from '../../../../assets/content/CreateWizard/DataPeriod/Sections/Final';
import useDataPeriod from '../../../../hooks/DataPeriod/useDataPeriod';

interface DataCreateProps {
  className?: string;
};

const DataCreate: React.FC<DataCreateProps> = ({
  className = "",
}) => {
  const navigate = useNavigate();
  const [years, setYears] = useState(2);
  const {
    dataPeriod,
    createDataPeriod,
    updateDataPeriod,
    loading
  } = useDataPeriod();

  const handleOnComplete = () => {
    createDataPeriod()
      .then((res) => navigate(res ? `../${res.id}` : '..', { replace: true }));
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    if (values["years"]) {
      setYears(values["years"]);
    } else {
      updateDataPeriod(values);
    }
  }

  return (
    <Wizard
      className={className}
      pages={getDataPeriodWizardPages()}
      values={{
        ...dataPeriod,
        years
      }}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Create Data Period',
        subtitle: 'Welcome to your Location Creator Wizard, this Wizard will help create a new Location to use in our Data Collection!'
      }}
      endPage={{
        name: 'Final',
        title: 'Create Data Period',
        subtitle: 'Welcome to the data collection wizard. Here you can provide us with all the data required to calculate your carbon footprint. Make use of the tooltip icon buttons if you need more guidance and refer back to the data collection guide if needs be.',
        children: (
          <FinalSection
            values={{
              ...dataPeriod,
              years
            }}
          />
        ),
      }}
      submitLoading={loading}
      onComplete={handleOnComplete}
      onBack={() => navigate(-1)}
    />
  );
}

export default DataCreate;
