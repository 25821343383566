import DataPeriodSection from "./Sections/BaselineYear";
import ReportTitleSection from "./Sections/Title";
import ReportTypeSection from "./Sections/Type";
import { CreateWizardPage, CreateWizardPages } from "../../../../types/CreateWizard";

const getPages = (): CreateWizardPages => ([
  {
    name: 'Title',
    title: 'Report Title',
    subtitle: "Please enter the title for this report.",
    children: ReportTitleSection,
    isNextDisabled: (values) => values?.["title"] === undefined || values?.["title"].trim() === ''
  },
  {
    name: 'Type',
    title: 'Report Type',
    subtitle: "Please choose a type for this report.",
    children: ReportTypeSection,
    isNextDisabled: (values) => values?.["reportType"] === -1 || values?.["reportType"] === undefined
  },
  {
    name: 'Baseline Year',
    title: 'Report Baseline Year',
    subtitle: "Select a Baseline year for this report. This allows us to set a baseline to reference the other years off of, to show your progress towards Net Zero.",
    children: DataPeriodSection,
    isNextDisabled: (values) => (values?.["baselineYear"] || 0) === 0
  }
] as (CreateWizardPage | boolean)[]).filter(Boolean) as CreateWizardPages;


export default getPages;