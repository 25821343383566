import { mapActions } from "./Action";
import { ImpactAreaGroupType } from "./ImpactArea";
import { Activity, Activities, mapActivities } from "./Activity";

export interface ScopeCategory extends ImpactAreaGroupType {
  scope: number;
  tcO2e: number;
  percent: number;
  children?: Activities;
}

export type ScopeCategories = ScopeCategory[];

export const mapScopeCategories = (data: {[key: string]: unknown}[]): ScopeCategories => {
  return data.map((item:  {[key: string]: unknown | unknown[] | any}) => ({
    ...item,
    id: item.id,
    statusCount: item.statusCounts,
    actionsTotal: item?.activities?.reduce((s: number, ng: Activity) => s + (ng.actions?.length || 0), 0),
    tco2e: item.tcO2e,
    scope: item.scopeId,
    ...(item?.actions ? {actions: mapActions(item.actions as {[key: string]: unknown}[])} : {}),
    ...(item?.activities ? {children: mapActivities(item.activities as {[key: string]: unknown}[])} : {}),
  })) as unknown[] as ScopeCategories;
}
