import { API_COMPANY_TASK } from "../../constants";
import { checkAuthorised, checkErrors } from "../../helpers/checkAuthorisedAPI";
import { formatCompanyIdQueryString } from "../../helpers/formatCompanyId";
import { CompanyTasks, mapCompanyTasks } from "../../types/CompanyTask";

export const getCompanyTasks = (id: string): Promise<CompanyTasks> => {
  return fetch(`${API_COMPANY_TASK}/getTaskList?${formatCompanyIdQueryString(id)}`, {
    headers: { "X-CSRF": "1" },
    method: 'GET',
  })
    .then((res) => checkAuthorised(res))
    .then((res) => checkErrors(res))
    .then((res) => mapCompanyTasks(res.payload).sort((a, b) => a.id < b.id ? 1 : -1).sort((a, b) => a.completed && !b.completed ? 1 : -1));
}