import './styles.scss';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard } from '@flotilla/component-library';

import { getReportWizardPages } from '../../../../assets/content/CreateWizard';
import useCreateReport from '../../../../hooks/Report/useCreateReport';
import FinalSection from '../../../../assets/content/CreateWizard/Report/Sections/Final';

interface ReportCreateProps {
  className?: string;
};

const ReportCreate: FC<ReportCreateProps> = ({
  className = ""
}) => {
  const navigate = useNavigate();
  const {
    createReport,
    saveCreateReport,
    updateCreateReport,
    loading
  } = useCreateReport();

  const handleOnComplete = () => {
    saveCreateReport()
      .then((res) => navigate(res ? `../${res}` : '..', { replace: true }));
  }
  
  const handleOnChange = (values: { [key: string]: any }) => {
    updateCreateReport(values);
  }

  return (
    <Wizard
      className={className}
      pages={getReportWizardPages()}
      values={createReport}
      onChange={handleOnChange}
      startPage={{
        name: 'Initial',
        title: 'Create Report',
        subtitle: 'Welcome to your Report Creator Wizard, this Wizard will help create a new Report!'
      }}
      endPage={{
        name: 'Final',
        title: 'Create Report',
        subtitle: 'Well done you are ready to create your Report.',
        children: <FinalSection values={createReport}/>,
      }}
      submitLoading={loading}
      onComplete={handleOnComplete}
      onBack={() => navigate(-1)}
    />
  );
}

export default ReportCreate;
