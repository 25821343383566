import './styles.scss';
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Avatar, DownloadIcon, IconButton, Table, useAlert } from '@flotilla/component-library';

import { AuditDataPeriod, DataPeriod } from '../../../../../../types/DataPeriod';
import { getAudits, getAuditsCSV } from '../../../../../../api/DataPeriod';
import { useCompanyId } from '../../../../../../context';
import formatMeasure from '../../../../../../helpers/measureFormatter';
import { numberLocaleFormatter } from '../../../../../../helpers/numberFormatter';
import { getInitials } from '../../../../../../helpers/getInitials';
import { User } from '../../../../../../types/User';

interface AuditingTabContentProps {
  className?: string;
  dataPeriod?: DataPeriod;
};

const MONETARY_FIELDS = [
  "Turnover",
  "CostOfSales",
  "BalanceSheet"
];

const FINANCIAL_DISPLAY_NAMES = {
  "CostOfSales": "Cost of sales",
  "BalanceSheet": "Balance sheet",
  "Fte": "FTE"
};

type ColumnKey = keyof typeof FINANCIAL_DISPLAY_NAMES;

const AuditingTabContent: React.FC<AuditingTabContentProps> = ({
  className,
  dataPeriod
}) => {
  const { addAlert } = useAlert();
  const companyId = useCompanyId();
  const [audits, setAudits] = useState<AuditDataPeriod[]>();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  useEffect(() => {
    if(dataPeriod && !audits) {
      getAudits(dataPeriod.id, companyId)
      .then(res => {
        setAudits(res.filter(r => !r.measure || r.measure !== "m2"));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPeriod, companyId]);

  const formatChangeValue = (value: string | number | null, columnName: string, measure?: string) => {
    var isEstimation = false;
    if(columnName === 'Status') return value;
    if(value === null) return '';
    
    if(typeof value === "string") {
      isEstimation = value.endsWith(" (Estimated)");
      value = isEstimation ? value.split(" (Estimated)")[0] : value;
    }

    var formattedValue = measure ? 
      formatMeasure((parseFloat(value.toString()) ?? 0) as number, measure, false) :
      MONETARY_FIELDS.includes(columnName) ? 
        `£${numberLocaleFormatter((value ?? 0) as number, 2)}`:
        value;

    return `${formattedValue}${isEstimation ? ' (Estimated)' : ''}`;
  }

  const handleDownloadAuditsCSV = async () => {
    if(dataPeriod) {
      setIsDownloadLoading(true);
      await getAuditsCSV(dataPeriod.id, companyId)
        .then((blob) => {
          saveAs(blob, 'Data Period Audit.csv');
        })
        .catch(() => {
          addAlert({
            id: `Downloading Audit Data CSV ${companyId}`,
            type: 'error',
            title: 'Error Downloading Audit Data CSV',
            content:
              'Something went wrong when downloading aduti data, please try again',
          });
        })
        .finally(() => {
          setIsDownloadLoading(false);
        });
    }
  }

  return (
    <section id="auditing-tab-content" className={className}>
      <IconButton
          id="download"
          isLoading={isDownloadLoading}
          onClick={(event) => {
            event.stopPropagation();
            handleDownloadAuditsCSV();
          } }
          icon={<DownloadIcon stroke="#1A343B" />}
          variant='ghost'
          title='Export to CSV'
          aria-label='Export to CSV'/>
      <Table
        stickyHeader
        dataTable
        filters={[]}
        headers={[
          {
            key: "who",
            label: "Who"
          },
          {
            key: "date",
            label: "When (UTC)"
          },
          {
            key: "what",
            label: "What"
          },
          {
            key: "originalValue",
            label: "Original Value"
          },
          {
            key: "newValue",
            label: "Updated Value"
          }
        ]}
        data={audits as unknown as { [key: string]: string; }[]}
        rowComponent={(item) => {
            const {
              id,
              date,
              user,
              change,
              measure,
              location
            } = item as unknown as AuditDataPeriod;
            let dateObject = new Date(date);
            let oldValue = formatChangeValue(change.originalValue, change.columnName, measure);
            let newValue = formatChangeValue(change.newValue, change.columnName, measure);

            return (
              <tr key={id}>
                <td className='name-cell'>
                  <section>
                    <Avatar text={getInitials(user as User)} />
                    {user?.fullName}
                  </section>
                </td>
                <td>{dateObject.toLocaleDateString()} {dateObject.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                <td>{FINANCIAL_DISPLAY_NAMES[change.columnName as ColumnKey] ?? change.columnName}{location ? ` (${location})` : ''}</td>
                <td>{oldValue}</td>
                <td>{newValue}</td>
              </tr>
            )
          }}
      />
    </section>
  );
}

export default AuditingTabContent;
