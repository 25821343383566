import './styles.scss';

import { useState } from 'react';
import { IconButton, Tag, Menu, MenuItem, DownloadIcon, MoreIcon, useAlert } from '@flotilla/component-library';

import { useCompanyId, useUserAccess } from '../../../../../context';
import { CompanyDocument, CompanyReport } from '../../../../../types/Report';
import ConfirmVisibilityToggleModal from './ConfirmVisibilityToggleModal/ConfirmVisibilityToggleModal';
import { numberLocaleFormatter } from '../../../../../helpers/numberFormatter';
import useReportActions from '../../../../../hooks/Report/useReportActions';
import { getReportDataCSV } from '../../../../../api/Report';
import { saveAs } from 'file-saver';

type ReportListItemProps = {
  data: CompanyReport | CompanyDocument;
  onClick?: () => void;
  onEditClick?: (data: CompanyDocument) => void;
  onDelete?: () => void;
};

const ReportListItem: React.FC<ReportListItemProps> = ({
  data: initialData,
  onClick = () => ({}),
  onEditClick = () => ({}),
  onDelete = () => ({}),
}) => {
  const {
    data,
    download,
    publishReport,
    deleteDocument,
    update,
    loading,
  } = useReportActions(initialData);
  const {
    title,
    reportTypeString,
  } = data;
  const { addAlert } = useAlert();
  const { userAccess } = useUserAccess();
  const { ADMIN, DELETE, EDIT, PE } = userAccess;
  const companyId = useCompanyId();
  const [changingVisibility, setChangingVisibility] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleOnClick = () => {
    if ((data as CompanyReport)?.reportId) {
      onClick();
    }
  };

  const handleDownloadClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    download();
    setShowMenu(false);
  }

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    onEditClick(data as CompanyDocument);
    setShowMenu(false);
  }

  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    deleteDocument()
      .then(onDelete);
    setShowMenu(false);
  }

  const handleMoreClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    !showMenu && setShowMenu(true);
  }

  const handleVisibleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setChangingVisibility(true);
    setShowMenu(false);
  }

  const handlePublishClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    publishReport((data as CompanyReport).status !== 'Published');
    setShowMenu(false);
  }

  const handleDownloadReportCSV: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    var reportId = (data as CompanyReport)?.reportId;
    if (reportId) {
      setShowMenu(false);
      await getReportDataCSV(Number(reportId), companyId)
        .then((blob) => {
          saveAs(blob, 'ReportData.csv');
        })
        .catch((error) => {
          addAlert({
            id: `Report Download CSV Failed ${reportId}`,
            type: 'error',
            title: 'Error Downloading Report Data CSV',
            content:
              'Something went wrong when downloading your Report Data, please try again',
          });
          console.log('Download Report Data CSV Error: ', error);
        });
    }
  }

  return (
    <tr className={`report-list-item ${!ADMIN ? 'report-list-item--read-only' : ''}`} onClick={handleOnClick}>
      <td id="title">
        <section id="title-container">
          <p>{title || ""}</p>
          <section id="tags-container">
            <Tag className="tag" size='small'>{reportTypeString}</Tag>
            {(data as CompanyReport)?.status && (
              <Tag className="tag" size='small'>{(data as CompanyReport).status}</Tag>
            )}
            {(data as CompanyReport)?.hidden && (
              <Tag className="tag" size='small'>Hidden</Tag>
            )}
          </section>
        </section>
      </td>
      <td id="reporting-period">
        <p>{(data as CompanyReport)?.yearEnds || ""}</p>
      </td>
      <td id="employee">
        <p>{(data as CompanyReport)?.totaltCO2e ? numberLocaleFormatter(Math.round((data as CompanyReport)?.totaltCO2e)) : ""}</p>
      </td>
      <td id="more">
        <section id="actions">
          <IconButton
            variant="ghost"
            aria-label="Download"
            icon={<DownloadIcon variant='dark' />}
            onClick={handleDownloadClick}
            isLoading={loading}
          />
          <IconButton
            variant="ghost"
            aria-label="More"
            icon={<MoreIcon variant='dark' />}
            onClick={handleMoreClick}
            isLoading={loading}
          />
          { showMenu && (
            <Menu
              id="list-item-menu"
              onClose={() => setShowMenu(false)}
            >
              <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
              { ADMIN && !(PE && companyId !== "0") && (data as CompanyReport)?.reportId ? (
                <MenuItem onClick={handlePublishClick}>{(data as CompanyReport).status !== "Published" ? "Publish Document" : "Unpublish Document"}</MenuItem>
              ) : <></>}
              { ADMIN && !(PE && companyId !== "0") && (data as CompanyReport)?.reportId ? (
                <MenuItem onClick={handleVisibleClick}>{(data as CompanyReport)?.hidden ? "Show Document" : "Hide Document"}</MenuItem>
              ) : <></>}
              { EDIT && !(PE && companyId !== "0") && (data as CompanyDocument)?.carbonReportUrl ? (
                <MenuItem onClick={handleEditClick}>Edit</MenuItem>
              ) : <></>}
              { DELETE && !(PE && companyId !== "0") && ((data as CompanyDocument)?.id || (data as CompanyReport).status !== "Published") ? (
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
              ) : <></>}
              { EDIT && !(PE && companyId !== "0") ? <MenuItem onClick={handleDownloadReportCSV}>Download Report Data CSV</MenuItem> : <></> }
            </Menu>
          )}
        </section>
      </td>
      {changingVisibility && (
        <ConfirmVisibilityToggleModal
          onClose={() => { setChangingVisibility(false) }}
          onSuccess={() => { setChangingVisibility(false); update({hidden: !(data as CompanyReport)?.hidden}) }}
          reportId={(data as CompanyReport)?.reportId}
          hiding={!(data as CompanyReport)?.hidden}
        />
      )}
    </tr>
  );
};

export default ReportListItem;
