import './App.scss';

import { useEffect, useState, FC } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AlertProvider, FlotillaProvider, Spinner } from '@flotilla/component-library';

import { isUserLoggedIn } from './api/authentication';
import Layout from './components/Layout';
import { AccessType, getUserRoleAccess, LOQATE_API_KEY } from './constants';
import { CompanyNameContext, UserAccessContext } from './context';
import { useAppDispatch } from './helpers/hooks';
import ActionManager from './pages/Administration/ActionManager';
import ActionRanking from './pages/Administration/ActionRankingManager';
import Company from './pages/Company';
import Customers from './pages/Customers/List';
import Home from './pages/Home';
import { setId, setName } from './reducers/user';
import Users from './pages/Users';
import FABContent from './components/FABContent';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [initialUserAccess, setInitialUserAccess] = useState<AccessType>();
  const [userAccess, setUserAccess] = useState<AccessType>();
  const [userCompanyName, setUserCompanyName] = useState<string>("");
  
  useEffect(() => {
    isUserLoggedIn()
      .then((res) => {
        if (res) {
          dispatch(setId(res.id));
          dispatch(setName(res.name));
          setInitialUserAccess(getUserRoleAccess(res.roles || [], res.companyType || "", res.productType || ""));
          setUserAccess(getUserRoleAccess(res.roles || [], res.companyType || "", res.productType || ""));
          setUserCompanyName(res.companyName || "");
        }
      });
  }, [dispatch]);

  if (userAccess === undefined || initialUserAccess === undefined) {
    return (
      <article>
        <Spinner />
      </article>
    )
  }
  
  const router = createBrowserRouter([
    {
      path: "/*",
      element: <Layout><Outlet/></Layout>,
      children: userAccess.ADMIN || !userAccess.STANDARD ? ([
        {
          index: true,
          element: <Home />
        },
        {
          path: 'customers',
          element: <Customers />
        },
        (userAccess.ADMIN ? {
          path: 'administration',
          children: [
            {
              path: 'actionmanager/*',
              element: <ActionManager />
            },
            {
              path: 'actionranking/*',
              element: <ActionRanking />
            }
          ]
        } : {}),
        {
          path: 'manageusers/*',
          element: <Users />
        },
        {
          path: ':companyId/*',
          element: <Company />
        }, 
        {
          path: '*',
          element: <Navigate to="/" />
        }
      ]) : ([
        {
          path: '*',
          element: <Company />
        },
        userAccess?.MANAGE_USER ? {
          path: 'manageusers',
          element: <Users />
        } : {},
      ])
    }
  ]);

  return (
    <UserAccessContext.Provider value={{ userAccess, initialUserAccess, updateUserAccess: setUserAccess }}>
      <CompanyNameContext.Provider value={userCompanyName}>
        <FlotillaProvider LOQATE_KEY={LOQATE_API_KEY} showFAB fabProps={{ children: <FABContent /> }}>
          <AlertProvider className="alert-container" duration={5000}>
            <RouterProvider router={router}/>
          </AlertProvider>
        </FlotillaProvider>
      </CompanyNameContext.Provider>
    </UserAccessContext.Provider>
  );
}

export default App;
